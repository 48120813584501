import { useContext, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  AppContext,
  FieldConditions,
  FieldRetraction,
  SpecialConditionsLink,
  StepConfirmation,
  useConfig,
} from "@homeserve/od-funnel-lib"
import { Box, Divider, Typography } from "@mui/material"
import { BtnNext } from "../../../components"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { LinkModalIframe } from "../../../components/LinkModalIfram"
import { useTheme } from "@mui/material/styles"
import { AdyenWrapper } from "../../../components/AdyenWrapper"
import Logo from "../../../images/alma.png"

import { Helmet } from "react-helmet"
export function Validation() {
  const ADYEN_FEATURES = true
  const { state } = useContext(AppContext)
  const { t } = useTranslation()
  const adyenSubmit = useRef<() => Promise<void>>()
  const [adyenValid, setAdyenValid] = useState<boolean>(!ADYEN_FEATURES)
  const [loading, setLoading] = useState<boolean>(false)
  const [adyenErrorMessage, setAdyenErrorMessage] = useState<string>(``)
  const theme = useTheme()
  const sendDataToGTM = useGTMDispatch()
  const config = useConfig()
  const link = (
    <LinkModalIframe
      title={`politique de données personnelles.`}
      href="https://depannage.homeserve.fr/politique-de-confidentialite"
    >
      politique de données personnelles.
    </LinkModalIframe>
  )
  useEffect(() => {
    return sendDataToGTM({
      event: `checkout`,
      PageType: `Page validation`,
      category: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress?.postCode,
      sku: `DL - ` + state.product.id,
      name: `DL - ` + state.product.name,
      price: `DL - ` + state.product.price,
      quantity: 1,
      brand: `DL - ` + config.partnerName,
      orderId: `DL - ` + state.internalRef.orderId,
      jobId: `DL - ` + state.internalRef.jobId,
    })
    // eslint-disable-next-line
    }, []);

  function NextButton() {
    return (
      <BtnNext
        loading={loading}
        label="Je valide ma commande"
        disabled={!adyenValid || !state.customer.giveUpRightOfReturn || !state.customer.termsAndConditionsAccepted}
      />
    )
  }

  const preSubmit = async (): Promise<void> => {
    setAdyenErrorMessage(``)
    if (loading) throw new Error(`Loading`)
    const submit = adyenSubmit.current
    if (!submit) throw new Error(`adyenComponent is not loaded`)
    setLoading(true)

    return submit()
      .catch(reason => {
        console.error(`validation`, reason)
        setAdyenErrorMessage(`Une erreur est survenue veuillez réessayer plus tard`)
        throw new Error(reason)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function DisclaimerMethodPyment() {
    const methodeName = state.internalRef.paymentMethode
    if (methodeName === `alma`) {
      return <Typography variant={`caption`}>{t(`steps.validation.almaPartnerDisclaimer2`)}</Typography>
    }

    return <Typography variant={`caption`}>{t(`steps.validation.almaPartnerDisclaimer`)}</Typography>
  }

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.validation`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepConfirmation
        btnNext={NextButton}
        preSubmit={ADYEN_FEATURES ? preSubmit : undefined}
        stepperColor={theme.palette.primary.main}
        stepperColorSecondary={theme.palette.secondary.main}
      >
        <Box sx={{ py: 2 }}>
          <Typography variant={`h1`} sx={{ pb: 2, fontSize: 24 }}>
            {ADYEN_FEATURES ? t(`steps.validation.title`) : t(`steps.validation.oldTitle`)}
          </Typography>
          {ADYEN_FEATURES && (
            <>
              <Box sx={{ borderRadius: `8px`, backgroundColor: theme.palette.secondary.light, p: 1, mb: 2 }}>
                <Typography variant={`body1`}>
                  <strong>{t(`steps.validation.disclaimer1`)}</strong> <br />
                  {t(`steps.validation.disclaimer2`)}
                </Typography>
              </Box>
              <Typography sx={{ mb: 1 }} variant={`h5`}>
                {t(`steps.validation.paymentMethod`)}
              </Typography>
              <Box
                sx={{
                  borderLeft: `3px solid ${theme.palette.secondary.main}`,
                  pl: 2,
                  mb: 2,
                  display: `flex`,
                  justifyContent: `space-between`,
                }}
              >
                <Typography sx={{ flex: 1 }}>{state.product.name}</Typography>
                <Typography variant={`body1`} sx={{ flex: 1, textAlign: `right` }}>
                  <strong>
                    {Intl.NumberFormat(`fr-FR`, {
                      style: `currency`,
                      currency: `EUR`,
                    }).format(Number(state.product.price))}
                  </strong>
                </Typography>
              </Box>
              <AdyenWrapper
                amount={0}
                orderId={state.internalRef.orderId}
                onLoadingChange={setLoading}
                onComponentReady={val => (adyenSubmit.current = val)}
                onValidChange={setAdyenValid}
                customerEmail={state.customer.email}
              />
              <Box sx={{ pt: 1.5, pb: 2.5 }}>
                <DisclaimerMethodPyment />
              </Box>
            </>
          )}
          <Divider />
          <Box sx={{ display: `flex`, alignItems: `center`, py: 1 }}>
            <img style={{ width: `90px` }} alt="Alma logo" src={Logo} />
            <Typography variant={`body2`}>{t(`steps.validation.infoAlma`)}</Typography>
          </Box>
          <Divider />
          {!state.internalRef.paymentOk && (
            <>
              <Box style={{ marginTop: 10 }}>
                <FieldRetraction
                  label={
                    <>
                      {t(`steps.validation.retraction`)} <span style={{ color: `#258386` }}>(Requis)</span>
                    </>
                  }
                />
              </Box>
              <Box>
                <FieldConditions
                  label={
                    <>
                      {t(`steps.validation.conditions`)}&nbsp;
                      <LinkModalIframe
                        href={`https://depannage.homeserve.fr/conditions-generales-de-vente`}
                        title={`conditions générales de vente`}
                      >
                        conditions générales de vente
                      </LinkModalIframe>
                      &nbsp;
                      {t(`steps.validation.conditions1`)}
                      <SpecialConditionsLink
                        linkParams={{
                          jobId: state.internalRef?.jobId,
                          lastName: state.customer?.billingAddress?.lastName,
                          orderReference: state.internalRef?.orderReference,
                        }}
                      >
                        {t(`steps.validation.conditions2`)}
                      </SpecialConditionsLink>
                      . <span style={{ color: `#258386` }}>(Requis)</span>
                    </>
                  }
                />
              </Box>
            </>
          )}
        </Box>
        {adyenErrorMessage && (
          <p style={{ textAlign: `center` }}>
            <Typography variant={`caption`} color={`red`}>
              {adyenErrorMessage}
            </Typography>
          </p>
        )}
      </StepConfirmation>
      <Typography style={{ textAlign: `center`, paddingBottom: 20 }} color={`gray`} variant={`body2`}>
        {t(`steps.validation.conditions3`)}
      </Typography>
      <Typography variant={`body2`}>
        {t(`steps.validation.moreDetails`)} {link}
      </Typography>
    </>
  )
}
