import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AppContext, useProduct, Loader, useConfig } from "@homeserve/od-funnel-lib"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"

import { BtnBack, BtnNext, Layout } from "../../components"
import { useTheme } from "@mui/material/styles"
import {
  AddCircleOutlineOutlined,
  ArrowBackIos,
  ArrowDropDown,
  ArrowRight,
  CheckOutlined,
  RemoveCircleOutlineOutlined,
  InfoRounded,
} from "@mui/icons-material"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import useCollapse from "react-collapsed"
import Logo from "../../images/mbp-logo.jpeg"
import LogoAlma from "../../images/alma.png"
import { useTranslation } from "react-i18next"
import { Helmet } from "react-helmet"
const formatDuration = (minutes: number): string => {
  const { hour, min } = {
    hour: Math.floor(minutes / 60),
    min: Math.ceil(minutes % 60),
  }
  if (hour && min) {
    return `${hour}h${min.toString(10).padStart(2, `0`)}`
  } else if (hour) {
    return `${hour}h`
  }

  return `${min} min`
}
export function Product() {
  const theme = useTheme()
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ behavior: `smooth`, top: 0 })
  }, [])
  function MoveBack() {
    const lastDiag = window.localStorage.getItem(`lastDiagnosticNavigation`)
    if (lastDiag) {
      navigate(lastDiag)
    } else {
      navigate(`/diagnostic`)
    }
  }

  function MoveNext() {
    navigate(`../funnel`)
  }
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const config = useConfig()
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const sendDataToGTM = useGTMDispatch()
  const noweb = state.product.noweb.includes(`noweb`)

  useEffect(() => {
    return sendDataToGTM({
      event: `productDetail`,
      PageType: `Page Produit`,
      category: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress?.postCode,
      sku: `DL - ` + state.product.id,
      name: `DL - ` + state.product.name,
      price: `DL - ` + state.product.price,
      quantity: 1,
      brand: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, [state.product]);

  useProduct({ productId: state.product.id }).catch(e => {
    console.error(`impossible to get product`, e)
  })

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.product`) + ` ` + state.product.id + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      {!state.product.name ? (
        <Box
          sx={{
            height: `100vh`,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
            backgroundColor: `white`,
          }}
        >
          <Loader primaryColor={theme.palette.primary.main} secondaryColor={theme.palette.secondary.main} />
        </Box>
      ) : (
        <>
          <Stack pt={2} pl={2} mb={0} direction="row" spacing={2} justifyContent={`space-between`} alignItems={`end`}>
            <BtnBack onClick={MoveBack} startIcon={<ArrowBackIos />} />
          </Stack>
          {state.product.name && (
            <Layout>
              <Box sx={{ pb: 8 }}>
                <Grid container spacing={{ xs: 0, md: 2 }}>
                  <Grid xs={12} md={10}>
                    <Box>
                      <Typography variant={`h1`} sx={{ fontSize: 24 }}>
                        {state.product.name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={2}>
                    <div style={{ display: `flex`, alignItems: `center`, justifyContent: `right` }}>
                      <Typography variant={`body2`} sx={{ fontSize: 14, mt: 1 }}>
                        <span>
                          Avec&nbsp;<strong>MesBonsPros</strong>
                        </span>
                      </Typography>
                      <img src={Logo} alt="" width="auto" height="40px" style={{ float: `right` }} />
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={{ xs: 0, md: 2 }}>
                  <Grid xs={12} md={7} sx={{ pb: 0 }}>
                    <Box sx={{ my: 2, width: `fit-content`, margin: `16px auto` }}>
                      <img src={state.product.image} alt={state.product.name} style={{ maxWidth: `100%` }} />
                      <Box sx={{ textAlign: `right`, color: theme.palette.grey[500] }}>
                        <Typography sx={{ fontSize: 12 }}>Photo non contractuelle</Typography>
                      </Box>
                    </Box>
                    <Box sx={{ my: 1, textAlign: `left` }} display={{ xs: `block`, md: `none` }}>
                      <Typography
                        variant={`body2`}
                        dangerouslySetInnerHTML={{
                          __html: state.product.condition,
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid xs={12} md={5} sx={{ pb: 0 }}>
                    {state.product.details && (
                      <Box display={{ xs: `block`, md: `none` }}>
                        <Button
                          variant="text"
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: `bold`,
                            textDecoration: `underline`,
                            paddingLeft: 0,
                          }}
                          {...getToggleProps()}
                        >
                          {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
                          {` `}
                          {isExpanded ? t(`product.readmore`) : t(`product.readmore`)}
                        </Button>
                        <Box {...getCollapseProps()}>
                          <Box sx={{ my: 1, textAlign: `left` }}>
                            <Typography
                              variant={`body2`}
                              dangerouslySetInnerHTML={{
                                __html: state.product.details,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                    <Box sx={{ my: 1, textAlign: `left` }}>
                      <TableContainer sx={{ mb: 2, maxWidth: 400, mx: `auto` }}>
                        <Table
                          size={`small`}
                          padding={`none`}
                          sx={{
                            fontSize: 8,
                            color: theme.palette.grey[600],
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: `none`,
                              py: 0,
                              mb: 0,
                            },
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell>
                                <Typography variant={`body1`}>
                                  <span style={{ fontWeight: `bold` }}>Votre devis provisoire :{` `}</span>
                                </Typography>
                              </TableCell>
                              <TableCell align={`right`}>
                                <Typography variant={`body1`}>
                                  <span
                                    style={{
                                      fontWeight: `bold`,
                                      fontSize: 30,
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    {state.product.price} €{` `}
                                  </span>
                                  <span
                                    style={{
                                      fontWeight: `bold`,
                                      fontSize: 14,
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    TTC*
                                  </span>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Box>
                      <TableContainer sx={{ mb: 2, maxWidth: 400, mx: `auto` }}>
                        <Table
                          size={`small`}
                          padding={`none`}
                          sx={{
                            fontSize: 8,
                            color: theme.palette.grey[600],
                            [`& .${tableCellClasses.root}`]: {
                              borderBottom: `none`,
                              pb: 1,
                              pt: 0,
                              mb: 0,
                            },
                          }}
                        >
                          <TableBody>
                            {parseInt(state.product.spareParts) > 0 && (
                              <TableRow>
                                <TableCell>Pièces et fournitures</TableCell>
                                <TableCell sx={{ textAlign: `right` }}>{state.product.spareParts} €</TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell>Frais de déplacement</TableCell>
                              <TableCell sx={{ textAlign: `right` }}>{state.product.callOut} €</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                Jusqu'à {formatDuration(Number(state.product.jobDuration))} de main d'oeuvre
                              </TableCell>
                              <TableCell sx={{ textAlign: `right` }}>{state.product.jobWorkforce} €</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                    <Divider />
                    <Box sx={{ display: `flex`, alignItems: `center`, py: 1 }}>
                      <img style={{ width: `90px` }} alt="Alma logo" src={LogoAlma} />
                      <Typography variant={`body2`}>{t(`product.alma`)}</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ pt: 2 }} display={{ xs: `block`, md: `none` }}>
                      <Stack direction={`row`} spacing={1}>
                        <Box sx={{ width: `32px` }}>
                          <InfoRounded style={{ color: `#145156`, fontSize: `32px` }} />
                        </Box>
                        <Box>
                          <Typography variant={`body2`} sx={{ fontSize: 12, mb: 2 }}>
                            {t(`product.reassurance`)}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    <Box display={{ xs: `none`, md: `block` }} sx={{ my: 2 }}>
                      {!noweb ? (
                        <BtnNext onClick={MoveNext} style={{ display: `flex`, minWidth: `100%` }} />
                      ) : (
                        <Box>
                          <Typography textAlign={`center`} sx={{ mb: 2 }} variant={`body1`}>
                            Contactez-nous :
                          </Typography>
                          <Box
                            sx={{
                              minWidth: `100%`,
                              borderRadius: 32,
                              border: `1px solid`,
                              borderColor: theme.palette.text.primary,
                              textAlign: `center`,
                              fontSize: 18,
                              py: 1,
                            }}
                          >
                            <Typography variant={`body1`} sx={{ fontWeight: `bold` }}>
                              07 77 77 77 74
                            </Typography>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid xs={12} md={7} sx={{ pt: 0 }}>
                    <Box sx={{ my: 1, textAlign: `left` }} display={{ xs: `none`, md: `block` }}>
                      <Typography
                        variant={`body2`}
                        dangerouslySetInnerHTML={{
                          __html: state.product.condition,
                        }}
                      />
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <Card>
                        <CardContent sx={{ padding: 0 }}>
                          <TableContainer sx={{ mb: 0 }}>
                            <Table
                              size={`medium`}
                              sx={{
                                paddingBottom: 0,
                                marginBottom: 0,
                                [`& .${tableCellClasses.root}`]: {
                                  borderBottom: `none`,
                                  paddingBottom: 0,
                                  marginBottom: 0,
                                },
                              }}
                            >
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      verticalAlign: `top`,
                                      width: 32,
                                      padding: `16px 4px 0 12px`,
                                    }}
                                  >
                                    <CheckOutlined
                                      sx={{
                                        color: theme.palette.text.primary,
                                        width: 32,
                                        height: 32,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <strong>En toute confiance</strong>
                                    <br />
                                    Paiement à la fin du chantier et réparations garanties
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{
                                      verticalAlign: `top`,
                                      width: 32,
                                      padding: `16px 6px 0 12px`,
                                    }}
                                  >
                                    <CheckOutlined
                                      sx={{
                                        color: theme.palette.text.primary,
                                        width: 32,
                                        height: 32,
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <strong>Service client expert</strong>
                                    <br />
                                    En France et à vos côtés, 7j/7 de 7h à 21h
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </CardContent>
                      </Card>
                    </Box>
                    {state.product.inclusion && (
                      <Card sx={{ mb: 2 }}>
                        <CardHeader
                          title={`Inclus dans la prestation`}
                          sx={{
                            backgroundColor: theme.palette.grey[200],
                            py: 1,
                            px: 2,
                          }}
                          titleTypographyProps={{
                            variant: `h5`,
                            fontSize: 14,
                          }}
                          avatar={
                            <AddCircleOutlineOutlined
                              sx={{
                                color: theme.palette.success.main,
                                width: 32,
                                height: 32,
                              }}
                            />
                          }
                        />
                        <CardContent sx={{ pl: 5 }}>
                          <Typography
                            variant={`body2`}
                            dangerouslySetInnerHTML={{
                              __html: state.product.inclusion,
                            }}
                          />
                        </CardContent>
                      </Card>
                    )}
                    {state.product.exclusion && (
                      <Card sx={{ mb: 3 }}>
                        <CardHeader
                          title={`Non inclus dans la prestation`}
                          sx={{
                            backgroundColor: theme.palette.grey[200],
                            py: 1,
                            px: 2,
                          }}
                          titleTypographyProps={{
                            variant: `h5`,
                            fontSize: 14,
                          }}
                          avatar={
                            <RemoveCircleOutlineOutlined
                              sx={{
                                color: theme.palette.error.main,
                                width: 32,
                                height: 32,
                              }}
                            />
                          }
                        />
                        <CardContent sx={{ pl: 5 }}>
                          <Typography
                            variant={`body2`}
                            dangerouslySetInnerHTML={{
                              __html: state.product.exclusion,
                            }}
                          />
                        </CardContent>
                      </Card>
                    )}
                    {state.product.exclusion && (
                      <Box sx={{ mb: 3 }}>
                        <Typography variant={`body2`}>
                          Pour réaliser des travaux non inclus dans cette prestation, contactez-nous directement ou
                          notre prestataire afin d'obtenir un devis complémentaire.
                        </Typography>
                      </Box>
                    )}
                    {state.product.details && (
                      <Box display={{ xs: `none`, md: `block` }}>
                        <Button
                          variant="text"
                          sx={{
                            color: theme.palette.text.primary,
                            fontWeight: `bold`,
                            textDecoration: `underline`,
                            paddingLeft: 0,
                          }}
                          {...getToggleProps()}
                        >
                          {isExpanded ? <ArrowDropDown /> : <ArrowRight />}
                          {` `}
                          {isExpanded ? t(`product.readmore`) : t(`product.readmore`)}
                        </Button>
                        <Box {...getCollapseProps()}>
                          <Box sx={{ my: 1, textAlign: `left` }}>
                            <Typography
                              variant={`body2`}
                              dangerouslySetInnerHTML={{
                                __html: state.product.details,
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid xs={12} md={5} sx={{ pt: 0 }} display={{ xs: `block`, md: `none` }}>
                    <Box sx={{ mb: 1 }}>
                      <Typography variant={`body2`} sx={{ color: theme.palette.grey[500], fontSize: 12 }}>
                        *{` `}
                        {state.product.isVatRateHouseAgeDependant && (
                          <>
                            Sous réserve d’éligibilité au taux réduit de TVA de 10% applicable au logement utilisé à des
                            fins d’habitation et achevé depuis plus de deux ans.
                          </>
                        )}
                        Hors suppléments soirs, weekends et jours fériés. Le devis provisoire sera confirmé par le
                        professionnel sur place et pourra le cas échéant vous proposer un diagnostic différent. Votre
                        devis sera alors annulé et vous pourrez commander une autre prestation de notre catalogue que
                        vous serez libre d'accepter ou de refuser. En cas de refus de cette autre prestation, des frais
                        forfaitaires d'annulation de {state.product.cancellationFees} € seront facturés.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid xs={12} md={5} display={{ xs: `none`, md: `block` }}>
                    <Box>
                      <Stack direction={`row`} spacing={1}>
                        <Box sx={{ width: `32px` }}>
                          <InfoRounded style={{ color: `#145156`, fontSize: `32px` }} />
                        </Box>
                        <Box>
                          <Typography variant={`body2`} sx={{ fontSize: 12, mb: 2 }}>
                            {t(`product.reassurance`)}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                    <Box>
                      <Typography variant={`body2`} sx={{ color: theme.palette.grey[500], fontSize: 12 }}>
                        *{` `}
                        {state.product.isVatRateHouseAgeDependant && (
                          <>
                            Sous réserve d’éligibilité au taux réduit de TVA de 10% applicable au logement utilisé à des
                            fins d’habitation et achevé depuis plus de deux ans.
                          </>
                        )}
                        Hors suppléments soirs, weekends et jours fériés. Le devis provisoire sera confirmé par le
                        professionnel sur place et pourra le cas échéant vous proposer un diagnostic différent. Votre
                        devis sera alors annulé et vous pourrez commander une autre prestation de notre catalogue que
                        vous serez libre d'accepter ou de refuser. En cas de refus de cette autre prestation, des frais
                        forfaitaires d'annulation de {state.product.cancellationFees} € seront facturés.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Layout>
          )}
          {state.product.name && (
            <Box
              sx={{
                position: `fixed`,
                bottom: 0,
                width: `100%`,
                minWidth: `100%`,
                backgroundColor: `white`,
                py: 2,
                px: 2,
                justifyContent: `center`,
              }}
              display={{ xs: `flex`, md: `none` }}
            >
              {!noweb ? (
                <BtnNext onClick={MoveNext} style={{ display: `flex`, minWidth: `100%` }} />
              ) : (
                <Box sx={{ width: `100%`, textAlign: `center` }}>
                  <Box
                    sx={{
                      width: `100%`,
                      minWidth: `100%`,
                      borderRadius: 32,
                      border: `1px solid`,
                      borderColor: theme.palette.text.primary,
                      textAlign: `center`,
                      fontSize: 18,
                      py: 1,
                    }}
                  >
                    <Typography variant={`body1`}>
                      Contactez-nous : <span style={{ fontWeight: `bold` }}>07 77 77 77 74</span>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </>
      )}
    </>
  )
}
