import { Box, Typography, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
export function Mandatory() {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Box sx={{ mt: 3, mb: 1 }}>
      <Typography variant={`body2`} sx={{ color: theme.palette.grey[600] }}>
        <span>*</span> {t(`global.mandatoryField`)}
      </Typography>
    </Box>
  )
}
