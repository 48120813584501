import { useContext, useEffect } from "react"
import {
  AppContext,
  FieldOptOutPhone,
  FieldOptOutSnailMail,
  FieldPhoneNumber,
  StepForm,
  useConfig,
} from "@homeserve/od-funnel-lib"
import { Typography } from "@mui/material"
import { FormContainer, Highlight } from "../../../elements"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"
import { BtnBack, BtnNext, Mandatory } from "../../../components"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { LinkModalIframe } from "../../../components/LinkModalIfram"
import { Helmet } from "react-helmet"

export function Phone() {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const theme = useTheme()
  const link = (
    <LinkModalIframe title={`groupe Homeserve.`} href="https://depannage.homeserve.fr/nos-partenaires">
      groupe Homeserve.
    </LinkModalIframe>
  )
  const sendDataToGTM = useGTMDispatch()
  const config = useConfig()

  useEffect(() => {
    return sendDataToGTM({
      event: `content-view`,
      PageType: `Tunnel - Telephone`,
      Univers: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress.postCode,
      Partenaire: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, [state.customer]);
  const legal = (
    <Typography sx={{ color: theme.palette.text.secondary }} variant={`body2`}>
      {t(`steps.phone.legal`)}
    </Typography>
  )

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.phone`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepForm
        type={`customer`}
        btnBack={BtnBack}
        btnNext={BtnNext}
        legal={legal}
        stepperColor={theme.palette.primary.main}
      >
        <Typography variant={`h1`} sx={{ fontSize: 24, lineHeight: `1.6rem` }}>
          {t(`steps.phone.title`)}
        </Typography>
        <Highlight>{t(`steps.phone.highlight`)}</Highlight>
        <Mandatory />
        <FormContainer>
          <FieldPhoneNumber label={t(`steps.phone.label.input`)} />
          <FieldOptOutPhone
            label={
              <>
                {t(`steps.phone.label.checkbox`)} {link}
              </>
            }
          />
          <FieldOptOutSnailMail label={<>{t(`steps.phone.label.checkbox2`)}</>} />
        </FormContainer>
      </StepForm>
    </>
  )
}
