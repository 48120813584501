import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import { AutoDiagWrapper as AutoDiag } from "./auto-diag/auto-diag"
import { Product } from "./product/product"
import { Funnel } from "./funnel/funnel"
import { GetParams } from "@homeserve/od-funnel-lib"

export function Views() {
  const location = useLocation()
  GetParams(location)

  return (
    <>
      <Routes>
        <Route path="/product/*" element={<Product />} />
        <Route path="/funnel/*" element={<Funnel />} />
        <Route path="/diagnostic/:domain" element={<AutoDiag />} />
        <Route path="/diagnostic" element={<AutoDiag />} />
        <Route path="/diagnostic/:domain/*" element={<AutoDiag />} />
        <Route path={`/`} element={<Navigate to={`/diagnostic`} replace={true} />} />
        <Route path={`*`} element={<Navigate to={`/diagnostic`} replace={true} />} />
      </Routes>
    </>
  )
}
