import getProp from "./getProp"

export interface BrowserInfo {
  acceptHeader: string
  colorDepth: string
  language: string
  javaEnabled: boolean
  screenHeight: string
  screenWidth: string
  userAgent: string
  timeZoneOffset: number
}
export default function collectBrowserInfo(): BrowserInfo {
  const colorDepth = getProp(window, `screen.colorDepth`) || ``
  const javaEnabled = getProp(window, `navigator.javaEnabled`) ? window.navigator.javaEnabled() : false
  const screenHeight = getProp(window, `screen.height`) || `` // TODO: Shall we set this to null instead?
  const screenWidth = getProp(window, `screen.width`) || `` // TODO: Shall we set this to null instead?
  const userAgent = getProp(window, `navigator.userAgent`) || ``

  // IE <+ 10 supports navigator.browserLanguage instead of navigator.language
  const language = getProp(window, `navigator.language`) || getProp(window, `navigator.browserLanguage`) || `en`
  const d = new Date()
  const timeZoneOffset = d.getTimezoneOffset()

  return {
    acceptHeader: `*/*`,
    colorDepth,
    language,
    javaEnabled,
    screenHeight,
    screenWidth,
    userAgent,
    timeZoneOffset,
  }
}
