import { useContext, useEffect } from "react"
import { AppContext, FieldNotes, StepForm, useConfig } from "@homeserve/od-funnel-lib"
import { Typography, useTheme } from "@mui/material"
import { FormContainer, Highlight } from "../../../elements"
import { useTranslation } from "react-i18next"
import { BtnBack, BtnNext, Mandatory } from "../../../components"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { Helmet } from "react-helmet"

export function Note() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { state } = useContext(AppContext)
  const sendDataToGTM = useGTMDispatch()
  const isRequired = true
  const config = useConfig()

  useEffect(() => {
    return sendDataToGTM({
      event: `content-view`,
      PageType: `Tunnel - Notes`,
      Univers: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress.postCode,
      Partenaire: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, [state.customer]);

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.notes`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepForm type={`infos`} btnBack={BtnBack} btnNext={BtnNext} stepperColor={theme.palette.primary.main}>
        <Typography variant={`h1`} sx={{ fontSize: 24 }}>
          {t(`steps.notes.title`)}
          {` `}
          {!isRequired ? (
            <span
              style={{
                fontWeight: `normal`,
                fontSize: `18px`,
                color: theme.palette.grey[500],
                fontStyle: `italic`,
              }}
            >
              ({t(`global.optional`)})
            </span>
          ) : (
            <span
              style={{
                fontWeight: `bold`,
                fontSize: `18px`,
                color: theme.palette.primary.main,
              }}
            >
              ({t(`global.mandatory`)})
            </span>
          )}
          {` `}
        </Typography>
        <Highlight>{t(`steps.notes.highlight`)}</Highlight>
        {isRequired ? <Mandatory /> : ``}
        <FormContainer>
          <FieldNotes
            label={t(`steps.notes.label.input`)}
            required={isRequired}
            helperText={t(`steps.notes.label.helperText`)}
          />
        </FormContainer>
      </StepForm>
    </>
  )
}
