import AdyenCheckout from "@adyen/adyen-web"
import "@adyen/adyen-web/dist/adyen.css"
import styles from "./AdyenWrapper.module.css"
import { useContext, useEffect, useRef, useState } from "react"
import { CoreOptions } from "@adyen/adyen-web/dist/types/core/types"
import { PaymentMethod, PaymentMethodsResponseInterface } from "@adyen/adyen-web/dist/types/types"
import { OnPaymentCompletedData } from "@adyen/adyen-web/dist/types/components/types"
import { AppContext, useConfig } from "@homeserve/od-funnel-lib"
import collectBrowserInfo from "../utils/browserInfos"
import { Button, Box } from "@mui/material"
import { useNavigate } from "react-router-dom"

interface AdyenWrapperProps {
  orderId?: string
  amount: number
  onValidChange: (value: boolean) => void
  onLoadingChange: (value: boolean) => void
  onComponentReady: (submit: () => Promise<void>) => void
  customerEmail: string
}

const postOrderPayment = async ({
  customerEmail,
  paymentOption,
  paymentMethod,
  orderId,
  token,
}: {
  token: string
  orderId: string
  customerEmail: string
  paymentOption: string
  paymentMethod: any
}) => {
  const origin = window.location.href

  return await fetch(`${process.env.REACT_APP_SERVICE_ORDER_URL}/payment-token`, {
    method: `POST`,
    body: JSON.stringify({
      orderId,
      customerEmail,
      paymentMethod,
      shopperInteraction: `Ecommerce`,
      recurringProcessingModel: `CardOnFile`,
      paymentOption,
      browserInfo: collectBrowserInfo(),
      origin,
    }),
    headers: new Headers({
      "Content-Type": `application/json`,
      authorization: `Bearer ${token}`,
    }),
  }).then(blob => blob.text())
}
const fetchPaymentMethods = async (amount: number): Promise<PaymentMethodsResponseInterface> => {
  const paymentMethodsUrl = new URL(`${process.env.REACT_APP_SERVICE_ORDER_URL}/payment-methods`)
  paymentMethodsUrl.searchParams.append(`amount`, amount.toString(10))
  paymentMethodsUrl.searchParams.append(`currency`, `EUR`)

  return fetch(paymentMethodsUrl.href).then(blob => blob.json())
}
const cbDisplayName = `Pré-autorisation bancaire`

export function AdyenWrapper({
  orderId,
  amount,
  onLoadingChange,
  onComponentReady,
  onValidChange,
  customerEmail,
}: AdyenWrapperProps) {
  const { dispatch }: any = useContext(AppContext)
  const { accessToken } = useConfig()
  const divRef = useRef<HTMLDivElement>(null)
  // @ts-ignore
  const [paymentMethodsName, setPaymentMethodsName] = useState(`no`)
  const [firstRetry, setFirstRetry] = useState(false)
  const [retryPayment, setRetryPayment] = useState(false)
  const navigate = useNavigate()
  function useQuery() {
    return new URLSearchParams(window.location.search)
  }
  const paymentStatusParam = useQuery().get(`success`)

  const [paymentStatus, setPaymentStatus] = useState(``)

  useEffect(() => {
    if (paymentStatusParam) {
      setPaymentStatus(paymentStatusParam)
    }
  }, [paymentStatusParam])

  useEffect(() => {
    let paymentOption = ``
    const div = divRef.current
    if (!div || !orderId || isNaN(amount)) return
    onLoadingChange(true)
    let dropInComponent: any
    let submitListener: (result: string) => void
    const init = async () => {
      const paymentMethodsResponse = await fetchPaymentMethods(amount)
      const bankCard = paymentMethodsResponse.paymentMethods.find(element => element.type === `scheme`)
      if (bankCard) {
        bankCard.name = cbDisplayName
      }
      paymentMethodsResponse.paymentMethods = []
      paymentMethodsResponse.paymentMethods.push(bankCard as PaymentMethod)
      const configuration: CoreOptions = {
        amount: { value: amount, currency: `EUR` },
        paymentMethodsResponse,
        environment: process.env.REACT_APP_ADYEN_ENV,
        locale: `fr_FR`,
        clientKey: process.env.REACT_APP_ADYEN_CLIENT,
        onSubmit: async event => {
          if (!orderId) return
          const result = await postOrderPayment({
            orderId,
            paymentMethod: event.data.paymentMethod,
            paymentOption,
            customerEmail,
            token: accessToken,
          })
          submitListener(result)
        },
        onPaymentCompleted(state: OnPaymentCompletedData, component: any) {
          console.error(`Adyen on complete`, state)
        },
        analytics: {
          enabled: false,
        },
        onChange(state: any) {
          onValidChange(state.isValid)
        },
        removePaymentMethods: [`sepadirectdebit`],
        onError: (error, component) => {
          console.error(`ADYEN on error:`, error, component)
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
          },
        },
        showPayButton: false,
      }
      const checkout = await AdyenCheckout(configuration)
      function OnReady() {
        if (paymentStatusParam === `false` && !firstRetry) {
          dropInComponent.setStatus(`error`, {
            message: `Echec de la pré-autorisation bancaire. Nous vous conseillons de contacter votre banque.`,
          })
        } else {
          if (paymentStatusParam === `true`) {
            dropInComponent.setStatus(`success`, { message: `Pré-autorisation bancaire validée` })
            dispatch({ type: `set_customer_context`, payload: { giveUpRightOfReturn: true } })
            dispatch({ type: `set_customer_context`, payload: { termsAndConditionsAccepted: true } })
            dispatch({ type: `set_internal_ref_context`, payload: { paymentOk: true } })
            // check if token exists
          }
        }
      }

      return checkout
        .create(`dropin`, {
          openFirstPaymentMethod: true,
          onSelect: data => {
            onValidChange(data.isValid)
            paymentOption = `single_payment`
            setPaymentMethodsName(paymentOption)
          },
          onReady: () => OnReady(),
        })
        .mount(div)
    }

    init()
      .then(value => {
        dropInComponent = value
        onComponentReady(async () => {
          return new Promise((resolve, reject) => {
            const timeout = window.setTimeout(() => {
              reject(new Error(`Adyen timeout`))
            }, 10000)
            submitListener = (result: any) => {
              window.clearTimeout(timeout)
              const resultAction = JSON.parse(result)
              if (resultAction.action) {
                dropInComponent.handleAction(resultAction.action)
              }
              if (
                resultAction.resultCode === `Authorised` ||
                resultAction.resultCode === `Refused` ||
                resultAction.resultCode === `Deleting` ||
                resultAction.resultCode === `Pending`
              ) {
                if (resultAction.resultCode === `Authorised`) {
                  dropInComponent.setStatus(`success`, { message: `Pré-autorisation bancaire validée` })
                  dispatch({ type: `set_customer_context`, payload: { giveUpRightOfReturn: true } })
                  dispatch({ type: `set_customer_context`, payload: { termsAndConditionsAccepted: true } })
                  dispatch({ type: `set_internal_ref_context`, payload: { paymentOk: true } })
                } else if (
                  resultAction.resultCode === `Refused` ||
                  resultAction.resultCode === `Deleting` ||
                  !firstRetry
                ) {
                  dropInComponent.setStatus(`error`, {
                    message: `Echec de la pré-autorisation bancaire. Nous vous conseillons de contacter votre banque.`,
                  })
                  setPaymentStatus(`false`)
                } else {
                  dropInComponent.setStatus(`error`, {
                    message: `Une erreur est survenue. Veuillez réessayer ou contactez-nous par téléphone.`,
                  })
                  setPaymentStatus(`false`)
                }
              }
            }
            dropInComponent.submit()
          })
        })
        onLoadingChange(false)
      })
      .catch(reason => {
        console.error(`ADYEN`, reason)
      })

    return () => {
      dropInComponent && dropInComponent.unmount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, amount, customerEmail, divRef, orderId, retryPayment, paymentStatusParam])

  useEffect(() => {
    // alert(paymentMethodsName)
    dispatch({ type: `set_internal_ref_context`, payload: { paymentMethode: paymentMethodsName } })
  }, [paymentMethodsName, dispatch, divRef, amount])
  function RetryPayment() {
    setFirstRetry(false)
    setRetryPayment(false)
    setPaymentStatus(``)
    navigate(`/funnel/validation`)
    window.location.reload()
  }

  return (
    <>
      <div ref={divRef} id="dropin-container" className={styles.adyenWrapper} />
      {paymentStatus === `false` ? (
        <Box sx={{ textAlign: `center`, py: 2 }}>
          <Button variant={`contained`} color={`primary`} onClick={RetryPayment}>
            Réessayer
          </Button>
        </Box>
      ) : (
        ``
      )}
    </>
  )
}
