import { Box, ButtonBase, Stack, Typography, useTheme } from "@mui/material"
import { ArrowForwardIosOutlined } from "@mui/icons-material"
import { MouseEventHandler } from "react"

export interface AnswerComponentI {
  name: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}
export function AnswerComponent({ onClick, name }: AnswerComponentI) {
  const theme = useTheme()

  return (
    <ButtonBase
      onClick={onClick}
      sx={{
        backgroundColor: `white`,
        border: `2px solid`,
        borderColor: theme.palette.grey[400],
        borderRadius: `4px`,
        height: 50,
        width: `100%`,
        position: `relative`,
        boxSizing: `border-box`,
        color: theme.palette.text.primary,
        display: `flex`,
      }}
    >
      <Stack
        spacing={2}
        direction={`row`}
        sx={{
          alignContent: `center`,
          width: `100%`,
          pl: 2,
          pr: 2,
          alignItems: `center`,
        }}
      >
        {/* { image ? ( */}
        {/*    <> */}
        {/*      <Box sx={{ width: 40, height: 40, alignContent: 'center', display: 'inline-block'}}> */}
        {/*        <img src={image} alt={name} height="40" width="40"/> */}
        {/*      </Box> */}
        {/*      <Box sx={{ fontSize: 18, fontWeight: theme.typography.fontWeightMedium, textAlign: 'left', padding: '18px 0'}}> */}
        {/*        <Typography variant={"button"}>{name}</Typography> */}
        {/*      </Box> */}
        {/*    </> */}
        {/*  ) : */}
        <Box sx={{ fontSize: 18, textAlign: `left`, width: `100%` }}>
          <Typography variant={`button`}>{name}</Typography>
        </Box>
        {/* } */}
      </Stack>
      <ArrowForwardIosOutlined sx={{ paddingRight: `4px`, color: theme.palette.text.primary }} />
    </ButtonBase>
  )
}
