import { DidomiSDK } from "@didomi/react"

export const DidomiProvider = () => {
  return (
    <DidomiSDK
      apiKey="c8d3d217-f88e-4944-b900-7318d296d56e"
      iabVersion={2} // If you want to support the TCF v1∏, don't forget to change this value, even if you selected the TCF v2 in the console. This parameter will load the correct stub in the React Component
      gdprAppliesGlobally={true}
      sdkPath="https://sdk.privacy-center.org/"
      embedTCFStub={true}
    />
  )
}
