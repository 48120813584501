import { PropsWithChildren, useState } from "react"
import { IconButton, Link, Modal } from "@mui/material"
import { HighlightOff } from "@mui/icons-material"

export const LinkModalIframe = ({ children, href, title }: PropsWithChildren<{ href: string; title: string }>) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <>
      <Link
        component="a"
        type={`button`}
        sx={{ color: `#05102e`, textDecorationColor: `#05102e` }}
        variant="body2"
        onClick={e => {
          e.preventDefault()
          setOpen(true)
        }}
      >
        {children}
      </Link>
      <Modal sx={{ width: `95vw`, height: `100vh`, margin: `0 auto` }} open={open} onClose={() => setOpen(false)}>
        <>
          <div style={{ display: `flex`, justifyContent: `flex-end` }} onClick={() => setOpen(false)}>
            <IconButton size={`large`} color={`primary`} onClick={() => setOpen(false)}>
              <HighlightOff />
            </IconButton>
          </div>

          <iframe title={title} style={{ width: `100%`, height: `100%`, border: `none` }} src={href} />
        </>
      </Modal>
    </>
  )
}
