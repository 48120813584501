import { useContext, useEffect } from "react"
import { AppContext, StepView, useConfig } from "@homeserve/od-funnel-lib"
import { Box, Button, Grid, Stack, Typography, useTheme } from "@mui/material"
import { Item, Circle } from "../../../elements"
import { useTranslation } from "react-i18next"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import Logo from "../../../images/logo.svg"
import * as process from "process"
import { Helmet } from "react-helmet"

export function Confirmation() {
  const ADYEN_FEATURES = true
  const { state } = useContext(AppContext)
  const theme = useTheme()
  const config = useConfig()
  /*  const suivreCommande = () => {
    // console.log('validate')
    window.open(`${process.env.REACT_APP_DEPANNAGE_WEBSITE_URL}/suivi/${state.externalRef.trackingUUID}`, `_blank`)
  } */
  const { t } = useTranslation()

  const orderId = state.internalRef.orderId
  const link = new URL(
    state.infos?.callbackUri || process.env.REACT_APP_CALLBACK_URI || `https://family.gabby-appli.com`,
  )
  link.searchParams.append(`order`, orderId || ``)

  const sendDataToGTM = useGTMDispatch()
  useEffect(() => {
    return sendDataToGTM({
      event: `purchase`,
      PageType: `Page confirmation`,
      category: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress?.postCode,
      sku: `DL - ` + state.product.id,
      name: `DL - ` + state.product.name,
      price: `DL - ` + state.product.price,
      quantity: 1,
      brand: `DL - ` + config.partnerName,
      orderId: `DL - ` + state.internalRef.orderId,
      jobId: `DL - ` + state.internalRef.jobId,
    })
    // eslint-disable-next-line
  }, []);
  const legal = (
    <Typography sx={{ color: theme.palette.text.secondary }} variant={`body2`}>
      {ADYEN_FEATURES ? t(`steps.thanks.legal`) : ``}
    </Typography>
  )

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.confirmation`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepView legal={legal}>
        <Box>
          <Stack direction={`row`} spacing={2}>
            <Box sx={{ display: `flex`, flexDirection: `column` }}>
              <Box>
                <img src={Logo} alt="MesBonsPro" width="auto" height="40px" style={{ justifyContent: `left` }} />
              </Box>
              <Typography sx={{ my: 2 }} variant={`h2`}>
                {t(`steps.thanks.title`)}
              </Typography>
              <Typography sx={{ mb: 2 }} variant={`body1`}>
                {t(`steps.thanks.intro`, { email: state.customer.email })}
              </Typography>
              <Typography sx={{ mb: 2, fontWeight: `bold` }} variant={`body1`}>
                {t(`steps.thanks.next`)}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={2} md={2}>
                  <Item>
                    <Circle>1</Circle>
                  </Item>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Item>{t(`steps.thanks.step1`)}</Item>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Item>
                    <Circle>2</Circle>
                  </Item>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Item>{t(`steps.thanks.step2`)}</Item>
                </Grid>
                <Grid item xs={2} md={2}>
                  <Item>
                    <Circle>3</Circle>
                  </Item>
                </Grid>
                <Grid item xs={10} md={10}>
                  <Item>{ADYEN_FEATURES ? t(`steps.thanks.step3`) : t(`steps.thanks.step3old`)}</Item>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 2 }} variant={`body2`}>
                {t(`steps.thanks.tracking`)}
              </Typography>
            </Box>
          </Stack>
        </Box>
        {/*  <Box sx={{ mt: 2, display: `flex`, justifyContent: `left` }}>
        <Button
          onClick={suivreCommande}
          sx={{ textDecorationColor: theme.palette.text.primary, textDecoration: `underline` }}
          startIcon={<ArrowForwardIos />}
        >
          {t(`steps.thanks.button`)}
        </Button>
      </Box> */}
        <Box sx={{ mt: 4, display: `flex`, justifyContent: `center` }}>
          <Button variant={`contained`} color={`primary`} href={link.href}>
            {t(`global.home`)}
          </Button>
        </Box>
      </StepView>
    </>
  )
}
