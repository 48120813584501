import { Box, Typography, useTheme } from "@mui/material"

export interface QuestionComponentI {
  question: string
}
export function QuestionComponent({ question }: QuestionComponentI) {
  const theme = useTheme()

  return (
    <Box sx={{ mb: 2 }}>
      <Typography
        variant={`h2`}
        sx={{
          color: theme.palette.primary.main,
          fontSize: 20,
          fontWeight: 700,
        }}
      >
        {question}
      </Typography>
    </Box>
  )
}
