import { MouseEventHandler } from "react"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"

export interface BtnBackI {
  label?: string
  disabled?: boolean
  href?: string
  className?: string
  onClick?: MouseEventHandler
  startIcon?: any
}
export function BtnBack({ disabled, label, href, className, onClick, startIcon }: BtnBackI) {
  const { t } = useTranslation()

  return (
    <Button
      variant={`contained`}
      color={`secondary`}
      href={href}
      onClick={onClick}
      className={className}
      type={`submit`}
      startIcon={startIcon}
      disabled={disabled}
    >
      {label != null ? label : t(`global.back`)}
    </Button>
  )
}
