import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { AppContext, GetTotal, StepSummary, useConfig } from "@homeserve/od-funnel-lib"
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material"
import { BtnBack, BtnNext } from "../../../components"
import {
  AccountCircleOutlined,
  ConstructionOutlined,
  HomeOutlined,
  LocalSeeOutlined,
  ReceiptOutlined,
  SpeakerNotesOutlined,
} from "@mui/icons-material"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { ICustomer } from "@homeserve/od-funnel-lib/lib/esm/state/interfaces"
import { Helmet } from "react-helmet"
const isSameAddress = (a: ICustomer[`billingAddress`], b: ICustomer[`billingAddress`]): boolean => {
  return (
    a.town === b.town &&
    a.title === b.title &&
    a.additionalAddress === b.additionalAddress &&
    a.postCode === b.postCode &&
    a.street === b.street &&
    a.country === b.country &&
    a.firstName === b.firstName &&
    a.lastName === b.lastName &&
    a.streetNo === b.streetNo
  )
}
export function Summary() {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const theme = useTheme()
  const config = useConfig()

  const tva = state.product.houseAgeDependant
    ? state.customer.housingOlderThan2Years
      ? 20
      : 10
    : state.product.customerVatRate
  const sendDataToGTM = useGTMDispatch()

  useEffect(() => {
    return sendDataToGTM({
      event: `checkout`,
      PageType: `Page Recapitulatif`,
      category: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress?.postCode,
      sku: `DL - ` + state.product.id,
      name: `DL - ` + state.product.name,
      price: `DL - ` + state.product.price,
      quantity: 1,
      brand: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, [state.customer]);
  function NextButton() {
    return <BtnNext label={`Je valide mes informations`} />
  }

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.summary`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepSummary
        btnNext={NextButton}
        btnBack={BtnBack}
        stepperColor={theme.palette.primary.main}
        stepperColorSecondary={theme.palette.secondary.main}
      >
        <Typography sx={{ mb: 2, fontSize: 24 }} variant={`h1`}>
          {t(`steps.summary.title`)}
        </Typography>
        <Box sx={{ mx: `auto`, py: 2, maxWidth: 800 }}>
          <Box>
            <Card sx={{ mb: 2 }}>
              <CardHeader
                title={t(`steps.summary.category.infos`)}
                sx={{ backgroundColor: theme.palette.grey[200], py: 1, px: 2 }}
                titleTypographyProps={{
                  variant: `h5`,
                  fontSize: 16,
                }}
                avatar={
                  <AccountCircleOutlined
                    sx={{
                      color: theme.palette.primary.main,
                      width: 32,
                      height: 32,
                    }}
                  />
                }
              />
              <CardContent>
                <Typography variant={`body2`}>
                  {state.customer.title} {state.customer.lastName} {state.customer.firstName}
                </Typography>
                <Typography variant={`body2`}>{state.customer.phoneNumber}</Typography>
                <Typography variant={`body2`}>{state.customer.email}</Typography>
              </CardContent>
            </Card>
            <Card sx={{ mb: 2 }}>
              <CardHeader
                title={
                  !isSameAddress(state.customer.deliveryAddress, state.customer.billingAddress)
                    ? t(`steps.summary.category.deliveryAddress`)
                    : t(`steps.summary.category.sameAddress`)
                }
                sx={{ backgroundColor: theme.palette.grey[200], py: 1, px: 2 }}
                titleTypographyProps={{
                  variant: `h5`,
                  fontSize: 16,
                }}
                avatar={
                  <HomeOutlined
                    sx={{
                      color: theme.palette.primary.main,
                      width: 32,
                      height: 32,
                    }}
                  />
                }
              />
              <CardContent>
                <Typography variant={`body2`}>
                  {state.customer.deliveryAddress.title} {state.customer.deliveryAddress.lastName}
                  {` `}
                  {state.customer.deliveryAddress.firstName}
                  <br />
                  {state.customer.deliveryAddress.streetNo} {state.customer.deliveryAddress.street}
                  <br />
                  {state.customer.deliveryAddress.additionalAddress}
                  <br />
                  {state.customer.deliveryAddress.postCode} {state.customer.deliveryAddress.town}
                </Typography>
              </CardContent>
            </Card>
            {!isSameAddress(state.customer.deliveryAddress, state.customer.billingAddress) && (
              <Card sx={{ mb: 2 }}>
                <CardHeader
                  title={t(`steps.summary.category.billingAddress`)}
                  sx={{ backgroundColor: theme.palette.grey[200], py: 1, px: 2 }}
                  titleTypographyProps={{
                    variant: `h5`,
                    fontSize: 16,
                  }}
                  avatar={
                    <ReceiptOutlined
                      sx={{
                        color: theme.palette.primary.main,
                        width: 32,
                        height: 32,
                      }}
                    />
                  }
                />
                <CardContent>
                  <Typography variant={`body2`}>
                    {state.customer.billingAddress.title} {state.customer.billingAddress.lastName}
                    {` `}
                    {state.customer.billingAddress.firstName}
                    <br />
                    {state.customer.billingAddress.streetNo} {state.customer.billingAddress.street}
                    <br />
                    {state.customer.billingAddress.additionalAddress}
                    <br />
                    {state.customer.billingAddress.postCode} {state.customer.billingAddress.town}
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Box>

          <Card sx={{ mb: 2 }}>
            <CardHeader
              title={state.product.name}
              sx={{ backgroundColor: theme.palette.grey[200], py: 1, px: 2 }}
              titleTypographyProps={{
                variant: `h5`,
                fontSize: 16,
              }}
              avatar={
                <ConstructionOutlined
                  sx={{
                    color: theme.palette.primary.main,
                    width: 32,
                    height: 32,
                  }}
                />
              }
            />
            <CardContent sx={{ px: 1 }}>
              <TableContainer>
                <Table
                  size={`medium`}
                  sx={{
                    paddingBottom: 0,
                    marginBottom: 0,
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: `none`,
                      paddingBottom: 0,
                      marginBottom: 0,
                      pr: 1,
                      pl: 0,
                    },
                  }}
                >
                  <TableBody>
                    {parseInt(state.product.spareParts) > 0 && (
                      <TableRow>
                        <TableCell align={`left`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                          <Typography variant={`h6`} color={theme.palette.text.primary} fontSize={14}>
                            Pièces et fournitures
                          </Typography>
                        </TableCell>
                        <TableCell align={`right`}>
                          {tva === 10 ? state.product.spareParts : state.product.spareParts20} €
                        </TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell align={`left`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                        <Typography variant={`h6`} color={theme.palette.text.primary} fontSize={14}>
                          Jusqu'à {state.product.jobDuration} min de main d'oeuvre
                        </Typography>
                      </TableCell>
                      <TableCell align={`right`}>
                        {tva === 10 ? state.product.jobWorkforce : state.product.jobWorkforce20} €
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align={`left`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                        <Typography variant={`h6`} color={theme.palette.text.primary} fontSize={14}>
                          Frais de déplacement
                        </Typography>
                      </TableCell>
                      <TableCell align={`right`}>
                        {tva === 10 ? state.product.callOut : state.product.callOut20} €
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {state.voucher.code !== null && (
                <>
                  <Divider
                    sx={{
                      mt: 2,
                      mx: 1,
                      backgroundColor: theme.palette.secondary.main,
                      height: `2px`,
                    }}
                    variant={`middle`}
                  />
                  <TableContainer>
                    <Table
                      size={`medium`}
                      sx={{
                        paddingBottom: 0,
                        marginBottom: 0,
                        [`& .${tableCellClasses.root}`]: {
                          borderBottom: `none`,
                          paddingBottom: 0,
                          marginBottom: 0,
                          pr: 1,
                          pl: 0,
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell align={`left`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                            <Typography variant={`h6`} color={theme.palette.text.primary} fontSize={14}>
                              Code promotion "{state.voucher.code}"
                            </Typography>
                          </TableCell>
                          <TableCell align={`right`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                            - {state.voucher.amount} %
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              <Divider
                sx={{
                  my: 2,
                  mx: 1,
                  backgroundColor: theme.palette.secondary.main,
                  height: `2px`,
                }}
                variant={`middle`}
              />
              <TableContainer>
                <Table
                  size={`medium`}
                  sx={{
                    paddingBottom: 0,
                    marginBottom: 0,
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: `none`,
                      paddingBottom: 0,
                      marginBottom: 0,
                      pr: 1,
                      pl: 0,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell align={`left`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                        dont TVA {tva}%
                      </TableCell>
                      <TableCell align={`right`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                        {tva === 10 ? state.product.totalTva10 : state.product.totalTva20} €
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align={`left`} sx={{ fontWeight: theme.typography.fontWeightBold }}>
                        Total TTC {state.voucher.code !== null ? `(avec remise)` : ``}
                      </TableCell>
                      <TableCell
                        align={`right`}
                        sx={{
                          fontWeight: theme.typography.fontWeightBold,
                          fontSize: 18,
                        }}
                      >
                        <GetTotal product={tva === 10 ? state.product.price : state.product.price20} /> €
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
          {state.infos.imageUrl && state.infos.imageUrl.length > 0 && (
            <Card sx={{ mb: 2 }}>
              <CardHeader
                title={t(`steps.summary.category.picture`)}
                sx={{ backgroundColor: theme.palette.grey[200], py: 1, px: 2 }}
                titleTypographyProps={{
                  variant: `h5`,
                  fontSize: 16,
                }}
                avatar={
                  <LocalSeeOutlined
                    sx={{
                      color: theme.palette.primary.main,
                      width: 32,
                      height: 32,
                    }}
                  />
                }
              />
              <CardContent>
                {state.infos.imageUrl.map((image, index) => (
                  <div key={index}>
                    <img style={{ maxWidth: `100%` }} src={image} alt="" />
                  </div>
                ))}
              </CardContent>
            </Card>
          )}
          {state.infos.notes !== `` && (
            <Card>
              <CardHeader
                title={t(`steps.summary.category.comment`)}
                sx={{ backgroundColor: theme.palette.grey[200], py: 1, px: 2 }}
                titleTypographyProps={{
                  variant: `h5`,
                  fontSize: 16,
                }}
                avatar={
                  <SpeakerNotesOutlined
                    sx={{
                      color: theme.palette.primary.main,
                      width: 32,
                      height: 32,
                    }}
                  />
                }
              />
              <CardContent>
                <Typography>{state.infos.notes}</Typography>
              </CardContent>
            </Card>
          )}
        </Box>
        {/* <Highlight>{t(`steps.summary.highlight`)}</Highlight> */}
      </StepSummary>
    </>
  )
}
