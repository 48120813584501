import { useContext, useEffect } from "react"
import { AppContext, FieldFirstName, FieldLastName, FieldGender, StepForm, useConfig } from "@homeserve/od-funnel-lib"
import { Typography, useTheme } from "@mui/material"
import { FormContainer, Highlight } from "../../../elements"
import { useTranslation } from "react-i18next"
import { BtnBack, BtnNext, Mandatory } from "../../../components"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { LinkModalIframe } from "../../../components/LinkModalIfram"
import { Helmet } from "react-helmet"

export function Identity() {
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const theme = useTheme()
  const link = (
    <LinkModalIframe
      title={`politique de données personnelles.`}
      href="https://depannage.homeserve.fr/politique-de-confidentialite"
    >
      politique de données personnelles.
    </LinkModalIframe>
  )
  const sendDataToGTM = useGTMDispatch()
  const config = useConfig()
  useEffect(() => {
    return sendDataToGTM({
      event: `content-view`,
      PageType: `Tunnel - Nom Prenom`,
      Univers: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress.postCode,
      Partenaire: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, [state.customer]);

  const legal = (
    <Typography sx={{ color: theme.palette.text.secondary }} variant={`body2`}>
      {t(`steps.identity.legal`)} {link}
    </Typography>
  )

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.identity`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepForm
        type={`customer`}
        btnBack={BtnBack}
        btnNext={BtnNext}
        legal={legal}
        stepperColor={theme.palette.primary.main}
      >
        <Typography variant={`h1`} sx={{ fontSize: 24 }}>
          {t(`steps.identity.title`)}
        </Typography>
        <Highlight>{t(`steps.identity.highlight`)}</Highlight>
        <Mandatory />
        <FormContainer>
          <FieldGender />
          <FieldLastName label={t(`steps.identity.label.lastName`)} />
          <FieldFirstName label={t(`steps.identity.label.firstName`)} />
        </FormContainer>
      </StepForm>
    </>
  )
}
