import { useRoutes } from "react-router-dom"
import { Views } from "./views"
import { AppProvider, ConfigProvider, IFunnelConfig } from "@homeserve/od-funnel-lib"
import { ThemeConfig } from "./theme"
import "./css/app.css"
import { DidomiProvider } from "./components"
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook"

export function App() {
  const routes = useRoutes([{ path: `/*`, element: <Views /> }])
  const gtmParams = { id: `GTM-TF3DHQ4` }
  const config: IFunnelConfig = {
    orderServiceUrl: process.env.REACT_APP_SERVICE_ORDER_URL || ``,
    catalogServiceUrl: process.env.REACT_APP_SERVICE_CATALOG_URL || ``,
    voucherServiceUrl: process.env.REACT_APP_SERVICE_VOUCHER_URL || ``,
    tenantServiceUrl: process.env.REACT_APP_SERVICE_TENANT_URL || ``,
    addressServiceUrl: process.env.REACT_APP_SERVICE_ADDRESS_URL || ` `,
    affiliationCode: process.env.REACT_APP_AFFILIATE_CODE || ``,
    partnerTreeId: process.env.REACT_APP_PARTNER_TREE_ID || ``,
    partnerName: `Gabby`,
  }

  return (
    <ThemeConfig>
      <GTMProvider state={gtmParams}>
        <DidomiProvider />
        <ConfigProvider config={config}>
          <AppProvider>{routes}</AppProvider>
        </ConfigProvider>
      </GTMProvider>
    </ThemeConfig>
  )
}
