import { useContext, useEffect, useState } from "react"
import { BtnBack, Layout } from "../../components"
import { AppContext, Autodiag, useConfig } from "@homeserve/od-funnel-lib"
import { AnswerComponent } from "./elements/AnswerComponent"
import { QuestionComponent } from "./elements/QuestionComponent"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
export function AutoDiagWrapper() {
  const { state } = useContext(AppContext)
  const sendDataToGTM = useGTMDispatch()
  const config = useConfig()

  useEffect(() => {
    return sendDataToGTM({
      // event: `content-view`,
      // PageType: `Tunnel - Autodiag`,
      // Univers: `Tunnel`,
      // visitorPostalCode: state.customer.billingAddress?.postCode,
      // Partenaire: `Gabby`,
      event: `content-view`,
      brand: config.partnerName,
      visitorPostalCode: state.customer.billingAddress?.postCode,
    })
    // eslint-disable-next-line
  }, []);
  const location = useLocation()
  const navigate = useNavigate()
  const [navigation, setNavigation] = useState<string[]>([])
  const { t } = useTranslation()
  const { domain } = useParams()

  useEffect(() => {
    const path = location.pathname.substring(1).replace(/diagnostic\/?/gi, ``)
    const parsedNavigation = path.split(`/`).filter(f => !!f)
    setNavigation(parsedNavigation)
  }, [location])

  function onNavigationChange(navi: string[]) {
    const path = location.pathname.substring(1)
    setNavigation(navi)
    const nav = navi.join(`/`)
    if (nav === path) {
      return
    }
    const naviUrl = `/diagnostic/${nav}`
    window.localStorage.setItem(`lastDiagnosticNavigation`, naviUrl)
    navigate(naviUrl)
  }

  return (
    <>
      <Layout>
        <Helmet>
          <title>{t(`seoTitle.autodiag`) + `- Gabby`}</title>
          <meta name="description" content="Helmet application" />
        </Helmet>
        <Autodiag
          navigation={navigation}
          setNavigation={onNavigationChange}
          displayAnswer={AnswerComponent}
          displayQuestion={QuestionComponent}
          btnBack={BtnBack}
          domain={domain}
          domainTitle={t(`autodiag.title`)}
        />
      </Layout>
    </>
  )
}
