import { useContext, useEffect } from "react"
import {
  AppContext,
  FieldBillingAddress,
  FieldDeliveryAddress,
  FieldhousingOlderThan2Years,
  StepForm,
  useConfig,
} from "@homeserve/od-funnel-lib"
import { Box, Button, Typography, useTheme } from "@mui/material"
import { FormContainer } from "../../../elements"
import { useTranslation } from "react-i18next"
import useCollapse from "react-collapsed"
import { BtnBack, BtnNext, Mandatory } from "../../../components"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { Helmet } from "react-helmet"

export function Addresses() {
  const { t } = useTranslation()
  const { state, dispatch } = useContext(AppContext)
  const theme = useTheme()

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: state.customer.hasDiffAddresses,
    onCollapseStart: () => {
      dispatch({ type: `reset_billing_address`, payload: {} })
      dispatch({ type: `set_customer_context`, payload: { hasDiffAddresses: false } })
    },
    onExpandStart: () => {
      dispatch({ type: `set_customer_context`, payload: { hasDiffAddresses: true } })
    },
  })
  const sendDataToGTM = useGTMDispatch()
  const config = useConfig()

  useEffect(() => {
    return sendDataToGTM({
      event: `content-view`,
      PageType: `Tunnel - Adresse`,
      Univers: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress.postCode,
      Partenaire: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.address`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepForm type={`address`} btnBack={BtnBack} btnNext={BtnNext} stepperColor={theme.palette.primary.main}>
        <Box>
          <Typography variant={`h1`} sx={{ fontSize: 24 }}>
            {t(`steps.address.title`, { firstname: state.customer.firstName })}
          </Typography>
          <Mandatory />
          <FormContainer>
            <FieldDeliveryAddress
              labelFirstName={t(`steps.identity.label.firstName`)}
              labelLastName={t(`steps.identity.label.lastName`)}
              labelPostalCode={t(`steps.address.label.postalCode`)}
              labelStreet={t(`steps.address.label.address`)}
              labelAdditional={t(`steps.address.label.additionalAddress`)}
              helperAdditional={t(`steps.address.helperAdditional`)}
            />
          </FormContainer>
        </Box>
        <Box sx={{ mb: 2, justifyContent: `left`, display: `flex` }}>
          <Button
            variant="text"
            sx={{
              color: theme.palette.text.primary,
              fontWeight: `bold`,
              textDecoration: `underline`,
            }}
            {...getToggleProps()}
          >
            {` `}
            {`>`}
            {` `}
            {isExpanded ? t(`steps.address.same_address`) : t(`steps.address.different_address`)}
          </Button>
        </Box>
        <Box {...getCollapseProps()}>
          <FormContainer>
            <Typography variant={`h3`} sx={{ mb: 2 }}>
              {t(`steps.address.billingAddress`)}
            </Typography>
            <FieldBillingAddress
              labelFirstName={t(`steps.identity.label.firstName`)}
              labelLastName={t(`steps.identity.label.lastName`)}
              labelPostalCode={t(`steps.address.label.postalCode`)}
              labelStreet={t(`steps.address.label.address`)}
              labelAdditional={t(`steps.address.label.additionalAddress`)}
              helperAdditional={t(`steps.address.helperAdditional`)}
            />
          </FormContainer>
        </Box>
        {state.product.houseAgeDependant && (
          <Box>
            <FieldhousingOlderThan2Years
              label={t(`steps.address.label.housingOlderThan2Years`)}
              moreDetail={t(`steps.address.label.moreDetail`)}
              tooltip={t(`steps.address.label.tooltip`)}
            />
          </Box>
        )}
      </StepForm>
    </>
  )
}
