import { AppContext, StepProvider } from "@homeserve/od-funnel-lib"

import { Layout } from "../../components"
import { Phone, Email, Addresses, Summary, Note, Confirmation, Identity, Picture, Validation } from "./steps"
import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

export function Funnel() {
  const location = useLocation()
  const { state } = useContext(AppContext)
  const navigate = useNavigate()
  const [step, setStep] = useState<string | undefined>(`identity`)
  useEffect(() => {
    if (!state.product.id || !state.product.name) {
      navigate(`/diagnostic`)
    }
  }, [navigate, state.product])
  useEffect(() => {
    const path = location.pathname.substring(1).replace(/funnel\/?/gi, ``)
    setStep(path || `identity`)
    window.scrollTo({ behavior: `smooth`, top: 0 })
  }, [location])
  function onStepChange(stepValue: string | undefined) {
    const path = location.pathname.substring(1)
    setStep(stepValue)
    if (stepValue === path) {
      return
    }
    navigate(`/funnel/${stepValue}`)
  }
  const onPrevious = () => {
    navigate(`/product`)
  }

  return (
    <Layout>
      <StepProvider
        currentStep={step}
        onChange={onStepChange}
        onPreviousStart={onPrevious}
        steps={[
          { name: `identity`, children: <Identity /> },
          { name: `addresses`, children: <Addresses /> },
          { name: `email`, children: <Email /> },
          { name: `phone`, children: <Phone /> },
          { name: `note`, children: <Note /> },
          { name: `picture`, children: <Picture /> },
          { name: `summary`, children: <Summary /> },
          { name: `validation`, children: <Validation /> },
          { name: `confirmation`, children: <Confirmation /> },
        ]}
      ></StepProvider>
    </Layout>
  )
}
