import { styled } from "@mui/material/styles"
import { Box, Paper } from "@mui/material"

export const FormContainer = styled(Box)(({ theme }) => ({
  maxWidth: 800,
  marginLeft: `auto`,
  marginRight: `auto`,
  paddingTop: theme.spacing(4),
  // textAlign: 'left',
  color: theme.palette.text.primary,
}))

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
  textAlign: `left`,
  color: theme.palette.text.primary,
  "&.number": {
    color: theme.palette.primary.main,
    fontWeight: `bold`,
    textAlign: `right`,
  },
}))

export const Highlight = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(2),
  marginTop: 20,
  backgroundColor: theme.palette.secondary.light,
  borderRadius: `16px`,
  textAlign: `left`,
  color: theme.palette.text.primary,
}))

export const Circle = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  marginLeft: `auto`,
  marginRight: `auto`,
  borderRadius: `100%`,
  backgroundColor: theme.palette.secondary.main,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  color: theme.palette.text.primary,
  fontSize: `1.2rem`,
  fontWeight: 700,
}))
