import { useEffect, useContext } from "react"
import { AppContext, FieldEmail, StepForm, FieldOptInEmail, useConfig } from "@homeserve/od-funnel-lib"
import { Typography } from "@mui/material"

import { FormContainer } from "../../../elements"
import { useTranslation } from "react-i18next"
import { BtnBack, BtnNext, Mandatory } from "../../../components"
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { LinkModalIframe } from "../../../components/LinkModalIfram"
import { Helmet } from "react-helmet"
import { useTheme } from "@mui/material/styles"

export function Email() {
  const theme = useTheme()
  const { t } = useTranslation()
  const { state } = useContext(AppContext)
  const link = (
    <LinkModalIframe title="groupe Homeserve." href="https://depannage.homeserve.fr/nos-partenaires">
      groupe HomeServe.
    </LinkModalIframe>
  )
  const sendDataToGTM = useGTMDispatch()
  const config = useConfig()

  useEffect(() => {
    return sendDataToGTM({
      event: `content-view`,
      PageType: `Tunnel - Email`,
      Univers: `DL - Tunnel`,
      visitorPostalCode: state.customer.billingAddress.postCode,
      Partenaire: `DL - ` + config.partnerName,
    })
    // eslint-disable-next-line
  }, [state.customer]);

  return (
    <>
      <Helmet>
        <title>{t(`seoTitle.email`) + ` - Gabby`}</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <StepForm type={`customer`} btnBack={BtnBack} btnNext={BtnNext} stepperColor={theme.palette.primary.main}>
        <Typography variant={`h1`} sx={{ fontSize: 24, lineHeight: `1.6rem` }}>
          {t(`steps.email.title`)}
        </Typography>
        <Mandatory />
        <FormContainer>
          <FieldEmail label={t(`steps.email.label.input`)} />
          <FieldOptInEmail
            label={
              <>
                {t(`steps.email.label.checkbox`)} {link}
              </>
            }
          />
        </FormContainer>
      </StepForm>
    </>
  )
}
